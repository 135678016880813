import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { ToastContainer, toast } from 'react-toastify';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';
import appUrl from "../../../../constants/appUrl";
import AddEditFaq from "../components/AddEditFaq";
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router-dom';
const FaqByProduct = ({ channelID, productID,showAll  }) => {
  const [faqList, setFaqList] = useState([]);
  const history = useHistory();
  const [res, setRes] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [editable, setEditable] = useState(false); // To determine if it's edit mode or add mode
  const [rowSelect, setRowSelect] = useState(null); // FAQ being edited
  const isAdmin = localStorage.getItem('admin') === 'true';
  const GetAllFAQByID = async () => {
    const token = localStorage.getItem("token");
    try {
        if (showAll) {
            // Fetch all FAQs when showAll is true
            const response = await fetch(
                `${appUrl.baseUrl}api/FAQ/GetAllFAQ`, // Modify this API to get all FAQs
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setFaqList(data);
        } else if (channelID && productID) {
            // Fetch FAQs based on selected channel and product
            const response = await fetch(
                `${appUrl.baseUrl}api/FAQ/GetFilteredFAQs/${productID}/${channelID}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setFaqList(data);
        }
    } catch (error) {
        console.error("Failed to fetch FAQs:", error);
    }
};

  const accept = async (faq) => {
    try {
      const response = await axios.delete(`${appUrl.baseUrl}api/FAQ/DeleteFAQByID/${faq.id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      
      setRes(response.data);
      toast.success("Deleted Successfully");
      // Refresh the FAQ list after deletion
      GetAllFAQByID();
    } catch (error) {
      toast.error("Failed to delete. Please try again.");
      console.error("Error deleting FAQ:", error);
    }
  };

  const reject = () => {
    // Optionally handle reject action here
  };

  const confirm = (faq) => {
    confirmDialog({
      header: (
        <div className="custom-header mb-3 mt-3">
          <i className="pi pi-trash bg-reddd"></i>
        </div>
      ),
      message: (
        <strong>Are you sure you want to delete this FAQ?</strong>
      ),
      accept: () => accept(faq),
      reject: reject,
      acceptClassName: 'custom-btn-red',
      rejectClassName: 'custom-white-red',
      className: 'center-buttons no-close-button',
    });
  };

  const onEdit = (faq) => {
    setRowSelect(faq);
    setEditable(true);
    setDialog(true);
  };

  const editAction = async (rowData) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(`${appUrl.baseUrl}api/FAQ/GetFAQById/${rowData.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }

      });
      const data = response.data;
      setEditable(true);
      setRowSelect(data);
      setDialog(true);
    } catch (error) {
      error("Error fetching outage data by ID:", error);
      toast.error("Failed to fetch data for editing");
    }
  };

  const onHide = () => {
    setDialog(false);
    setRowSelect(null);
  };

  useEffect(() => {
    GetAllFAQByID();
  }, [productID, channelID, showAll, res]);

  return (
    <>
      <ToastContainer />
      <ConfirmDialog />
      <Dialog visible={dialog} onHide={onHide} header={editable ? "Edit FAQ's" : "Add FAQ's"} style={{ width: "60vw" }}>
        <AddEditFaq editable={editable} dialog={dialog} setDialog={setDialog} onHide={onHide} setRes={setRes} rowData={rowSelect} />
      </Dialog>

      <div className="grid " style={{display:'block'}}>
        <div className="md:col-12 ">
          <div className="table-customs">
            {faqList.length > 0 ? (
              <Accordion className="accordion-custom">
                {faqList.map((faq) => (
                  <AccordionTab
                    className="faqCustomAccordion"
                    header={
                      <React.Fragment>
                        <div className="flex justify-between items-center">
                          {faq.question}
                        </div>
                        {isAdmin && (
                        <div className="ml-auto">
                          <Button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              editAction(faq);
                            }}
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info p-mr-2"
                          />
                          <Button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              confirm(faq); // Pass the correct FAQ object
                            }}
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger ml-1"
                          />
                        </div>
                        )}
                      </React.Fragment>
                    }
                    key={faq.id}
                  >
                    {faq.answer}
                  </AccordionTab>
                ))}
              </Accordion>
            ) : (
              <div>No FAQs available for this product.</div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default FaqByProduct;
