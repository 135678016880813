import React,{useState,useEffect} from 'react'

import appUrl from "../../../../constants/appUrl";
import TableComplaintHandling from './TableComplaintHandling';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
const InAppComplaint = ({searchQuery}) => {
    const [complaintList, setComplaintList] = useState();
    const history = useHistory();
    const GetAllComplaint = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Complaint/GetAllComplaint`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            );
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setComplaintList(data?.filter(item => item?.channelId === 2))
        }
        catch (error) {

        }
    }
    useEffect(() => {
        GetAllComplaint();
      }, []);

  return (
    <>
         <ToastContainer />
         <TableComplaintHandling callCenter={complaintList} searchQuery={searchQuery}/>
    </>
 
  )
}

export default InAppComplaint