import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import appUrl from "../../../../constants/appUrl";
import Loader from '../../../components/laoder';
import FaqByProduct from './FaqByProduct';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import AddEditFaq from '../components/AddEditFaq';
import { Button } from 'primereact/button';

const Faqs = ({ searchQuery }) => {
    const [channelList, setChannelList] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState({ channelID: 'all', channelName: 'All' }); // Default to 'All'
    const [productList, setProductList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({ productId: 'all', productName: 'All' }); // Default to 'All'
    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [res, setRes] = useState(null);
    const history = useHistory();

    const isAdmin = localStorage.getItem('admin') === 'true';

    useEffect(() => {
        const getAllChannel = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 401) {
                    toast.error('Token expired. Please log in again.');
                    window.localStorage.clear();
                    history.push('./');
                    return;
                }
                const data = await response.json();
                setChannelList([{ channelID: 'all', channelName: 'All' }, ...data]);
            } catch (error) {
                console.error('Error fetching channels:', error);
            }
        };
        getAllChannel();
    }, [history]);

    useEffect(() => {
        const getAllProduct = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${appUrl.baseUrl}api/product/GetAllProducts`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 401) {
                    toast.error('Token expired. Please log in again.');
                    window.localStorage.clear();
                    history.push('./');
                    return;
                }
                const data = await response.json();
                setProductList([{ productId: 'all', productName: 'All' }, ...data]);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        getAllProduct();
    }, [history]);

    const onHide = () => {
        setDialog(false);
    };

    return (
        <>
            <ToastContainer />
            <Dialog visible={dialog} onHide={onHide} header={editable ? "Edit FAQ" : "Add FAQ"} style={{ width: "60vw" }}>
                <AddEditFaq editable={editable} dialog={dialog} setDialog={setDialog} onHide={onHide} setRes={setRes} />
            </Dialog>

            <div className='grid'>
                <div className='xl:col-10 md:col-8 sm:col-8 col-4'>
                    <h5 style={{ fontWeight: "bold" }}>FAQs</h5>
                </div>

                {isAdmin && (
                    <div className='xl:col-2 md:col-4 sm:col-4 col-8'>
                        <Button className="custom-btn w80" label="+ &nbsp; Add New" onClick={() => { setEditable(false); setDialog(true) }} />
                    </div>
                )}
            </div>

            {/* Combined Dropdowns in the Same Row */}
            <div className="p-fluid formgrid grid">
                <div className="field col-6 md:col-3">
                    <label>Select Channel</label>
                    <Dropdown
                        value={selectedChannel}
                        options={channelList}
                        onChange={(e) => setSelectedChannel(e.value)}
                        optionLabel="channelName"
                        placeholder="Choose a Channel"
                    />
                </div>
                <div className="field col-6 md:col-3">
                    <label>Select Product</label>
                    <Dropdown
                        value={selectedProduct}
                        options={productList}
                        onChange={(e) => setSelectedProduct(e.value)}
                        optionLabel="productName"
                        placeholder="Choose a Product"
                    />
                </div>
            </div>

            {loading && <Loader />}
            
            {/* Display FAQs Based on Selected Channel and Product */}
            {(selectedChannel.channelID === 'all' || selectedProduct.productId === 'all') ? (
                <div className="col-12">
                    {/* Render all FAQs when "All" is selected */}
                    <FaqByProduct showAll={true} />
                </div>
            ) : (
                selectedProduct && selectedChannel && (
                    <div className="col-12">
                        <FaqByProduct productID={selectedProduct.productId} channelID={selectedChannel.channelID} />
                    </div>
                )
            )}
        </>
    );
};

export default Faqs;
