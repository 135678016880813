import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AddEditAdminProduct from '../components/AddEditAdminProduct';
import appUrl from "../../../../constants/appUrl";
import { useHistory } from 'react-router-dom';
import ViewAttachment from '../components/ViewAttachment';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import CustomVideo from '../../../components/CustomVideo';
import Loader from '../../../components/laoder';

const AdminZProduct = ({ searchQuery }) => {

    const [dialog, setDialog] = useState();
    const [productList, setProductList] = useState();
    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [res, setRes] = useState(null)
    const [rowselect, setRowselect] = useState(null)
    const [editable, setEditable] = useState();
    const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
    const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const history = useHistory();

    const GetAllProducts = async () => {
        const token = localStorage.getItem('token')
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setProductList(data)
            setLoading(false);

        } catch (error) {

        }
    }




    const onHide = () => {
        setDialog(false)
        setEditable(false)
    }
    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };
    const editAction = (rowData) => {
        setEditable(true);
        setEditable(rowData);
        setDialog(true);
        setRowselect(rowData)
    }
    const onHideVideoAttachment = () => {
        setVideoAttachmentDialog(false);
    };

    const actionTemplate = (rowData) => {
        return (
            <div>

                <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
                <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
            </div>
        );
    }

    const accept = async (rowData) => {
        try {
            const response = await axios.delete(`${appUrl.baseUrl}api/Product/DeleteProductByID/${rowData?.productId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
            console.error("Error deleting disclaimer:", error);
        }
    };
    const reject = (rowData) => {
        return
    }
    const confirm = (rowData) => {
        confirmDialog({
            header: (
                <div className="custom-header mb-3 mt-3">
                    <i className="pi pi-trash bg-reddd"></i>

                </div>
            ),
            message: (
                <strong>Are you sure you want to delete this Product?</strong>
            ),
            accept: () => accept(rowData),
            reject: () => reject(rowData),
            acceptClassName: 'custom-btn-red',
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };

    const actionDocument = (rowData) => {
        const documentsToDownload = rowData.productAttachment.filter(attachment =>
            attachment.attachmentPath.endsWith('.docx') ||
            attachment.attachmentPath.endsWith('.pdf') ||
            attachment.attachmentPath.endsWith('.xlsx')
        );

        const documentPaths = documentsToDownload.map(document => document.attachmentPath);

        const hasImage = rowData.productAttachment.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));

        const hasVideo = rowData.productAttachment.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg'));

        return (
            <div>
                {documentsToDownload.length > 0 && (
                    <Button
                        icon="pi pi-download"
                        className="custom-btn-edit"
                        onClick={() => downloadDocuments(documentPaths)}
                    />
                )}
                {hasImage && (
                    <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                        setSelectedAttachment(rowData.productAttachment.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
                        setViewAttachmentDialog(true);
                    }} />
                )}
                {hasVideo && (
                    <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
                        setSelectedVideoAttachment(rowData.productAttachment.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg')));
                        setVideoAttachmentDialog(true);
                    }} />
                )}
                {(documentsToDownload.length === 0 && !hasImage && !hasVideo) && (
                    <Button
                        // icon="pi pi-question"
                        label='-'
                        className="custom-btn-edit"
                        tooltip="No attachment available"
                    />
                )}

            </div>
        );
    }


    const downloadDocuments = async (documentPaths) => {
        try {
            for (let i = 0; i < documentPaths.length; i++) {
                const documentPath = documentPaths[i];
             
                const response = await fetch(`${appUrl.baseUrl}${documentPath}`);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '');
                link.setAttribute('target', '_blank');
                link.click();
                URL.revokeObjectURL(url);
                await new Promise(resolve => setTimeout(resolve, 1000)); // Delay each download by 1 second
            }
        } catch (error) {
            console.error("Error downloading documents:", error);
            toast.error("Failed to download documents");
        }
    };

    const viewProduct = (rowData) => {


        history.push({
            pathname: '/product',
            state: { data: rowData },
        });
    }
    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                productList?.filter((item) =>
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                    item.overviewSummary?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                    item.termsAndConditions?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(productList);
        }
    }, [searchQuery, productList]);
    const isAdmin = localStorage.getItem('admin')
    useEffect(() => {
        GetAllProducts()
    }, [res])
    return (
        <>
            <ConfirmDialog />
            <ToastContainer />
            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
            </Dialog>
            <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
                {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
            </Dialog>
            <Dialog visible={dialog} onHide={onHide}
                header={editable ? "Edit Product" : "Add Product"}
                style={{ width: "60vw" }}>
                <AddEditAdminProduct editable={editable} dialog={dialog} rowData={rowselect} setRes={setRes} setDialog={setDialog} onHide={onHide} />
            </Dialog>


            <div className='grid'>
                <div className="xl:col-10 md:col-8 sm:col-8 col-4">
                    <h5 style={{ fontWeight: "bold" }}>Product</h5>
                </div>

                {isAdmin === 'true' || isAdmin === true ? (
                    <div className="xl:col-2 md:col-4 sm:col-4 col-8" >
                        <div >
                            <Button className="custom-btn w80 " label="+ &nbsp; Add New" onClick={() => { setEditable(false); setDialog(true) }} />
                        </div>
                    </div>
                ) : null}

                <div className='md:col-12 overflowX' >
                    {loading && <Loader />}
                    <div className='table-custom'>

                        <DataTable
                            value={filteredData}
                            scrollable
                            scrollHeight="600px"
                            rows={9}
                            paginator
                            className='overflowX'
                            onRowClick={(e) => viewProduct(e.data.productId)}
                        >
                            <Column header="Product" style={{ minWidth: '20rem', }} body={(rowData) => <Button className='product-button' style={{ textAlign: 'left' }} label={rowData.productName} onClick={(e) => {
                                e.stopPropagation();
                                viewProduct(rowData.productId);
                            }} />} />

                            <Column field="createdDate" header="Created Date/Time"
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true

                                })} />
                            <Column
                                field="updatedDate"
                                header="Updated Date/Time"
                                body={(rowData) => {
                                    if (!rowData.updatedDate) {
                                        return '-';
                                    }
                                    return new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    });
                                }}
                            />
                            <Column header="Attachments" body={actionDocument} style={{ minWidth: '12rem' }} />
                            {isAdmin === 'true' || isAdmin === true ? (
                                <Column header="Action" body={actionTemplate} style={{ minWidth: '10rem' }} />
                            ) : null}
                        </DataTable>
                    </div>
                </div>
            </div>



        </>
    )
}
export default AdminZProduct