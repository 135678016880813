import React, { useState, useEffect } from 'react'

import appUrl from "../../../../constants/appUrl";
import TableProcessInformation from './TableProcessInformation';
import TableVideos from './TableVideos';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
const InAppVideos = ({searchQuery}) => {
    const [postList, setPostList] = useState();
    const history = useHistory();
    const GetAllProcess = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Post/GetAllPost`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            );
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setPostList(data?.filter(item => item?.channelID === 2))
        }
        catch (error) {

        }
    }
    useEffect(() => {
        GetAllProcess();
    }, []);

    return (
        <>
             <ToastContainer />
             <TableVideos callCenter={postList} searchQuery={searchQuery}/>
        </>
      
    )
}

export default InAppVideos