import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppConfig } from './AppConfig';

import PrimeReact from 'primereact/api';

import { AppMenu } from './AppMenu';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import Faqs from './app/features/screens/faqs/pages/Faqs';
import ManageProduct from './app/features/screens/ManageProduct.js/pages/ManageProduct';
import ManageFaq from './app/features/screens/ManageFaq/pages/ManageFaq';
import Dashboard from './app/features/screens/Dashboard/pages/Dashboard';
import UserProcess from './app/features/screens/UserProcess/pages/UserProcess';
import UserComplaint from './app/features/screens/UserComplaint/pages/UserComplaint';
import UserSocialMedia from './app/features/screens/UserSocialMedia/pages/UserSocialMedia';
import AgainstProductProcess from './app/features/screens/AgainstProductProcess/pages/AgainstProductProcess';
import UserChat from './app/features/screens/UserChat/pages/UserChat';
import LoginScreen from './app/features/screens/auth/pages/login_screen';
import AdminZProduct from './app/features/screens/AdminZProduct/pages/AdminZProduct';
import AdminZAppJourney from './app/features/screens/AdminZAppJourney/pages/AdminZAppJourney';
import AdminZTrainingMaterial from './app/features/screens/AdminZTrainingMaterial/pages/AdminZTrainingMaterial';
import AdminTipOfDay from './app/features/screens/AdminTipOfDay/pages/AdminTipOfDay';
import AdminOutage from './app/features/screens/AdminOutage/pages/AdminOutage';
import AdminDisclaimer from './app/features/screens/AdminDisclaimer/pages/AdminDisclaimer';
import AdminDashboardSlider from './app/features/screens/AdminDashboardSlider/pages/AdminDashboardSlider';
import UserZAppJourney from './app/features/screens/UserZAppJourney/pages/UserZAppJourney';
import UserZTrainingMaterial from './app/features/screens/UserZTrainingMaterial/pages/UserZTrainingMaterial';
import Useroutage from './app/features/screens/UserOutage/pages/Useroutage';
import UserProduct from './app/features/screens/UserProduct/pages/UserProduct';
import Channel from './app/features/screens/Channel/pages/Channel';
import CallCenter from './app/features/screens/AdminChannel/pages/CallCenter';
import SocialMedia from './app/features/screens/AdminChannel/pages/SocialMedia';
import InApp from './app/features/screens/AdminChannel/pages/InApp';
import Banners from './app/features/screens/Banners';
import dashboardIcon from "./assets/KbIcons/Dashboard.svg";
import productIcon from "./assets/KbIcons/Product.svg";
import channelIcon from "./assets/KbIcons/Channel.svg";
import dotIcon from "./assets/KbIcons/Dot.svg";
import appJourneyIcon from "./assets/KbIcons/AppJourney.svg";
import trainingMaterialIcon from "./assets/KbIcons/TrainingMaterial.svg";
import tipIcon from "./assets/KbIcons/Tip.svg";
import outagesIcon from "./assets/KbIcons/Outages.svg";
import disclaimerIcon from "./assets/KbIcons/Disclaimer.svg";
import dashboardSliderIcon from "./assets/KbIcons/DashboardSlider.svg";
import processIcon from "./assets/KbIcons/Processs.svg";
import complainIcon from "./assets/KbIcons/Complain.svg";
import faqIcon from "./assets/KbIcons/Faq.svg";


const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const copyTooltipRef = useRef();
    const location = useLocation();
    PrimeReact.ripple = true;

    const handleSearch = (query) => {
     
        setSearchQuery(query);
    };

    const isAdmin = localStorage.getItem("admin") === 'true' || localStorage.getItem("admin") === true;
    const login = localStorage.getItem('login', true)

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = () => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const menu = [
        isAdmin === 'true' || isAdmin === true ? {
            items: [
                { label: 'Dashboard', icon: dashboardIcon, to: '/' },
                { label: 'Product', icon: productIcon, to: '/adminzproduct' },
                {
                    label: "Channel",
                    icon: channelIcon,
                    items: [
                        { label: "Call Center", icon: dotIcon, to: "/callcenter" },
                        { label: "Social Media", icon: dotIcon, to: "/socialmedia" },
                        { label: "In-App", icon: dotIcon, to: "/inapp" },
                    ],
                },
                { label: 'App Journey', icon: appJourneyIcon, to: '/adminzapplourney' },
                { label: 'Training Material', icon: trainingMaterialIcon, to: '/adminztrainingmaterial' },
                { label: 'Tip of the day', icon: tipIcon, to: '/admintipofday' },
                { label: 'Outages / Degradations', icon: outagesIcon, to: '/adminoutage' },
                { label: 'Disclaimer', icon: disclaimerIcon, to: '/admindisclaimer' },
                { label: 'Banners & Promotions', icon: dashboardSliderIcon, to: '/banner' },
                { label: 'Process Information', icon: processIcon, to: '/userprocess' },
                { label: "Complaint Handling", icon: complainIcon, to: "/usercomplaint" },
                { label: 'FAQs', icon: faqIcon, to: '/userfaq' },
            ]
        }
        :
        {
            items: [
                { label: 'Dashboard', icon: dashboardIcon, to: '/' },
                { label: 'Product', icon: productIcon, to: '/adminzproduct' },
                {
                    label: "Channel",
                    icon: channelIcon,
                    items: [
                        { label: "Call Center", icon: dotIcon, to: "/callcenter" },
                        { label: "Social Media", icon: dotIcon, to: "/socialmedia" },
                        { label: "In-App", icon: dotIcon, to: "/inapp" },
                    ],
                },
                { label: 'App Journey', icon: appJourneyIcon, to: '/adminzapplourney' },
                { label: 'Training Material', icon: trainingMaterialIcon, to: '/adminztrainingmaterial' },
                { label: 'Tip of the day', icon: tipIcon, to: '/admintipofday' },
                { label: 'Outages / Degradations', icon: outagesIcon, to: '/adminoutage' },
                { label: 'Bannners', icon: dashboardSliderIcon, to: '/admindashboardslider' },
                { label: 'Process Information', icon: processIcon, to: '/userprocess' },
                { label: "Complaint Handling", icon: complainIcon, to: "/usercomplaint" },
                { label: 'FAQs', icon: faqIcon, to: '/userfaq' },
            ]
        }
    ];
    

    const onToggleMenuClick = (event) => {

       
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });


    return (
        <>
            {localStorage.getItem("login") === null || localStorage.getItem("login") === undefined ? (
                <div className="layout-main-container auth_main" style={{ backgroundImage: 'url("assets/layout/images/Group.png")', padding: '9rem 1rem 0rem 0rem' }}>
                    <div className="layout-main">
                        <Route exact path="/" component={LoginScreen} />
                    </div>
                </div>
            ) : (
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <AppTopbar onToggleMenuClick={onToggleMenuClick} searchQuery={searchQuery} onSearch={handleSearch} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </div>
                    <div className="layout-main-container">
                        <div className="layout-main">


                            <Switch>
                                <Route exact path="/userproduct">
                                    <UserProduct searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/adminzproduct">
                                    <AdminZProduct searchQuery={searchQuery} />
                                </Route>

                                <Route exact path="/product">
                                    <ManageProduct searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/banner">
                                    <Banners searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/userzappjourney">
                                    <UserZAppJourney searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/adminzapplourney">
                                    <AdminZAppJourney searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/userztrainingmaterial">
                                    <UserZTrainingMaterial searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/adminztrainingmaterial">
                                    <AdminZTrainingMaterial searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/againstproductprocess">
                                    <AgainstProductProcess searchQuery={searchQuery} />
                                </Route>
                                {/* <Route exact path="/againstproductprocess" component={AgainstProductProcess} /> */}
                                <Route exact path="/admintipofday" component={AdminTipOfDay} />
                                <Route exact path="/adminoutage">
                                    <AdminOutage searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/useroutage">
                                    <Useroutage searchQuery={searchQuery} />
                                </Route>
                                 <Route exact path="/admindisclaimer" component={localStorage.getItem("admin") === 'true' || localStorage.getItem("admin") === true ? AdminDisclaimer : Dashboard} />
                                {/* <Route exact path="/admindisclaimer">
                                    <AdminDisclaimer searchQuery={searchQuery} />
                                </Route> */}
                                <Route exact path="/admindashboardslider" component={AdminDashboardSlider} />
                                <Route exact path="/usersocialmedia" component={UserSocialMedia} />

                                <Route exact path="/" component={Dashboard} />
                                <Route exact path="/userprocess"  >
                                    <UserProcess searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/channel" component={Channel} />
                               
                                <Route exact path="/callcenter">
                                    <CallCenter searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/inapp">
                                    <InApp searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/socialmedia">
                                    <SocialMedia searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/userfaq">
                                    <Faqs searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/userprocess">
                                    <UserProcess searchQuery={searchQuery} />
                                </Route>
                                <Route exact path="/usercomplaint">
                                    <UserComplaint searchQuery={searchQuery} />
                                </Route>
                                {/* <Route exact path="/userprocess" component={UserProcess} /> */}
                                <Route exact path="/faq" component={ManageFaq} />


                                {/* <Route exact path="/dashboard" component={localStorage.getItem("admin") === 'true' || localStorage.getItem("admin") === true ? Dashboard : null} /> */}

                                {/* <Route exact path="/channel" component={Channel} /> */}
                                {/* <Route exact path="/userfaq" component={Faqs} /> */}
                                <Route exact path="/userchat" component={UserChat} />
                                {/* <Route exact path="/usercomplaint" component={UserComplaint} /> */}

                            </Switch>
                        </div>
                    </div>
                    <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />
                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </div>
            )}
        </>
    );
}

export default App;
