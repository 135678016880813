    import React, { useState, useEffect } from 'react';
    import { DataTable } from 'primereact/datatable';
    import { Column } from 'primereact/column';
    import { Button } from 'primereact/button';
    import AddEditAdminTip from '../components/AddEditAdminTip';
    import { Dialog } from 'primereact/dialog';
    import appUrl from "../../../../constants/appUrl";
    import CustomDescription from '../../../components/CustomDescription';
    import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
    import { ToastContainer, toast } from 'react-toastify';
    import axios from 'axios';
    import Loader from '../../../components/laoder';
    import { useHistory } from 'react-router-dom';
    const AdminTipOfDay = () => {
        const history = useHistory();
        const [dialog, setDialog] = useState();
        const [tipList, setTipList] = useState();
        const [res, setRes] = useState();
        const [descriptionDialog, setDescriptionDialog] = useState(false);
        const [descriptionContent, setDescriptionContent] = useState('');
        const [loading, setLoading] = useState(false);
        const GetAllTips = async () => {
            const token = localStorage.getItem('token')
            try {
                setLoading(true);
                const response = await fetch(`${appUrl.baseUrl}api/Tips/GetTips`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                )
                if (response.status === 401) {
                    toast.error('Token expired. Please log in again.');
                    window.localStorage.clear();
                    history.push('./');
                    return;
                }
        
                const data = await response.json();
                setTipList(data)
                setLoading(false);
            }
            catch (error) {
                toast.error('Token expire. Please login again.');
                console.error('Error fetching channels:', error);
            }
        }

        const onHide = () => {
            setDialog(false)
        }

        const isAdmin = localStorage.getItem('admin')
        const stripHtmlTags = (html) => {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        };
        const showDescription = (description) => {
            setDescriptionContent(description);
            setDescriptionDialog(true);
        };
        const onHideDescriptionDialog = () => {
            setDescriptionDialog(false);
        };
        const actionTemplate = (rowData) => (
            <div>
                <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
            </div>
        );


        const accept = async (rowData) => {
            try {
                const response = await axios.delete(`${appUrl.baseUrl}api/Tips/DeleteTip/${rowData?.tipsID}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                });
                setRes(response.data);
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("Failed to delete. Please try again.");
                console.error("Error deleting disclaimer:", error);
            }
        };
        const reject = (rowData) => { };

        const confirm = (rowData) => {
            confirmDialog({
                header: (
                    <div className="custom-header mb-3 mt-3">
                        <i className="pi pi-trash bg-reddd"></i>

                    </div>
                ),
                message: (
                    <strong>Are you sure you want to delete this Tip of the day?</strong>
                ),
                accept: () => accept(rowData),
                reject: () => reject(rowData),
                acceptClassName: 'custom-btn-red',
                rejectClassName: 'custom-white-red',
                className: 'center-buttons no-close-button',
            });
        };
        useEffect(() => {
            GetAllTips()
        }, [res])
        return (
            <>
                <ConfirmDialog />
                <ToastContainer />
                <Dialog visible={dialog} onHide={onHide}
                    header={"Add Tip of the Day"}
                    style={{ width: "60vw" }}>
                    <AddEditAdminTip dialog={dialog} setRes={setRes} setDialog={setDialog} onHide={onHide} />
                </Dialog>
                <Dialog visible={descriptionDialog} onHide={onHideDescriptionDialog} header="Description" style={{ width: '50vw' }}>
                    <CustomDescription description={descriptionContent} />
                </Dialog>

                <div className='grid'>
                    <div className="xl:col-10 md:col-8 sm:col-8 col-4">
                        <h5 style={{ fontWeight: "bold" }}>Tip of the day</h5>
                    </div>
                
                    {isAdmin === 'true' || isAdmin === true ? (
                        <div className="xl:col-2 md:col-4 sm:col-4 col-8" >
                            <div >
                                <Button className="custom-btn w80 " label="+ &nbsp;Add New" onClick={() => { setDialog(true) }} />
                            </div>
                        </div>
                    ) : null}

                    <div className='md:col-12 overflowX' >
                        {loading && <Loader />}
                        <div className='table-custom'>
                            <DataTable
                                value={tipList}
                                scrollable
                                scrollHeight="500px"
                                rows={9}
                                className='overflowX'
                                paginator
                            >
                                <Column
                                    body={(rowData, index) => {
                                        return index.rowIndex + 1;
                                    }}
                                    header="Sr. No."
                                />
                                <Column
                                    header="Description"
                                    body={(rowData) => (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                showDescription(rowData.description);
                                            }}
                                            style={{ cursor: 'pointer', color: '#00b0ab', textDecoration: 'underline' }}
                                        >
                                            View Description
                                        </a>
                                    )}
                                />
                                <Column field='createdDate' header="Created Date / Time"
                                    body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    })} />

                                {isAdmin === 'true' || isAdmin === true ? (
                                    <Column header="Action" body={actionTemplate} />
                                ) : null}
                            </DataTable>
                        </div>
                    </div>
                </div>

            </>
        )
    }

    export default AdminTipOfDay