import React, { useState, useEffect } from 'react'

import appUrl from "../../../../constants/appUrl";
import TableProcessInformation from './TableProcessInformation';
import TableFaq from './TableFaq';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SocialFaqs = ({searchQuery}) => {
    const [faqList, setFaqList] = useState();
    const history = useHistory();
    const GetAllProcess = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/FAQ/GetAllFAQ`,  {
                method:'GET',
                headers:{
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            });
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            
            // let result = data?.filter(item => item?.channelId?.filter(item2 => item2?.channelId === 1))
            let result = data.filter(item => item.channelId && item.channelId.includes(1));
          
            setFaqList(result)
            
        }
        catch (error) {

        }
    }
    useEffect(() => {
        GetAllProcess();
    }, []);
    console.log('faqList', faqList)

    return (
        <>
             <ToastContainer />
             <TableFaq callCenter={faqList} searchQuery={searchQuery}/>
        </>
        
    )
}

export default SocialFaqs