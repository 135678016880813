import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import appUrl from "../../../../constants/appUrl";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AddEditAdminOutage from '../component/AddEditAdminOutage';
import Loader from '../../../components/laoder';
import { useHistory } from 'react-router-dom';
const AdminOutage = ({ searchQuery }) => {
    const [outageList, setOutageList] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [res, setRes] = useState(null);
    const [dialog, setDialog] = useState(false);
    const history = useHistory();
    const [editable, setEditable] = useState(false);
    const [rowselect, setRowselect] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');

    const GetOutageDegradation = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/OutageDegradation/GetAllOutageDegradation`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            const data = await response.json();
            setOutageList(data);
            setLoading(false);
        } catch (error) {
            toast.error("Failed to fetch data");
        }
    };

    const editAction = async (rowData) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${appUrl.baseUrl}api/OutageDegradation/GetOutageDegradationById/${rowData.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = response.data;
            setEditable(true);
            setRowselect(data);
            setDialog(true);
        } catch (error) {
            toast.error("Failed to fetch data for editing");
        }
    };

    const actionTemplate = (rowData) => (
        <div>
            <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
            <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => confirm(rowData)} />
        </div>
    );

    const accept = async (rowData) => {
        try {
            const response = await axios.delete(`${appUrl.baseUrl}api/OutageDegradation/DeleteOutageDegradationByID/${rowData?.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 401) {
                toast.error('Token expired. Please log in again.');
                window.localStorage.clear();
                history.push('./');
                return;
            }
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
        }
    };

    const reject = () => { };

    const confirm = (rowData) => {
        confirmDialog({
            header: (
                <div className="custom-header mb-3 mt-3">
                    <i className="pi pi-trash bg-reddd"></i>
                </div>
            ),
            message: (
                <strong>Are you sure you want to delete this outage / degradation?</strong>
            ),
            accept: () => accept(rowData),
            reject: () => reject(rowData),
            acceptClassName: 'custom-btn-red',
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };

    const onHide = () => {
        setDialog(false);
        setRowselect(null);
    };

    useEffect(() => {
        GetOutageDegradation();
    }, [res]);

    useEffect(() => {
        let filtered = outageList;

        if (searchQuery) {
            filtered = filtered.filter((item) =>
                item.productName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                item.reason?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                item.status?.toLowerCase().includes(searchQuery?.toLowerCase())
            );
        }

        if (startDateTime && endDateTime) {
            filtered = filtered.filter((item) => {
                const itemDateTime = new Date(item.createdDate);
                return itemDateTime >= new Date(startDateTime) && itemDateTime <= new Date(endDateTime);
            });
        }

        setFilteredData(filtered);
    }, [searchQuery, outageList, startDateTime, endDateTime]);

    const handleStartDateTimeChange = (e) => {
        setStartDateTime(e.target.value);
    };

    const handleEndDateTimeChange = (e) => {
        setEndDateTime(e.target.value);
    };

    const isAdmin = localStorage.getItem('admin');

    return (
        <>
        <ConfirmDialog />
            <ToastContainer />
            <Dialog visible={dialog} onHide={onHide} header={editable ? "Edit Outages / Degradations" : "Add Outages / Degradations"} style={{ width: "60vw" }}>
                <AddEditAdminOutage editable={editable} rowData={rowselect} dialog={dialog} setRes={setRes} setDialog={setDialog} onHide={onHide} />
            </Dialog>

            <div className='grid'>
                <div className=" md:col-6 sm:col-12 col-12">
                    <h5 style={{ fontWeight: "bold" }}>Outages / Degradations</h5>
                </div>
                <div className="md:col-2 sm:col-12 col-12">
                    <label className="mb-2" style={{ fontWeight: "bold" }}>Start Date & Time</label>
                    <InputText className="mbl_view w100" type='datetime-local' value={startDateTime} onChange={handleStartDateTimeChange} />
                </div>
                <div className="md:col-2 sm:col-12 col-12">
                    <label className="mb-2" style={{ fontWeight: "bold" }}>End Date & Time</label>
                    <InputText className="mbl_view w100" type='datetime-local' value={endDateTime} onChange={handleEndDateTimeChange} />
                </div>
                {isAdmin === 'true' || isAdmin === true ? (
                    <div className=" md:col-2 sm:col-4 col-12">
                        <Button className="custom-btn w80 mt-3" label="+ &nbsp; Add New" onClick={() => { setEditable(false); setDialog(true); }} />
                    </div>
                ) : null}
                <div className='md:col-12 overflowX'>
                    {loading && <Loader />}
                    <div className='table-custom'>
                        <DataTable
                            value={filteredData}
                            scrollable
                            scrollHeight="500px"
                            rows={9}
                            paginator
                            className='overflowX'
                        >

                            <Column header="Product " field='productName' className='left-border' />
                            <Column header="Reason" className='pointer' body={(rowData) => (
                                <span title={rowData.reason}>
                                    {rowData.reason.length > 15 ? `${rowData.reason.slice(0, 15)}...` : rowData.reason}
                                </span>
                            )} />

                            <Column field="status" header="Status" />
                            <Column field='createdDate' header="Created Date / Time"
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                })} />
                            <Column
                                field="updatedDate"
                                header="Updated Date/Time"
                                body={(rowData) => {
                                    if (!rowData.updatedDate) {
                                        return '-';
                                    }
                                    return new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    });
                                }}
                            />

                            {isAdmin === 'true' || isAdmin === true ? (
                                <Column header="Action" body={actionTemplate} style={{ minWidth: '10rem' }} />
                            ) : null}


                        </DataTable>
                    </div>
                </div>
            </div>

        </>
    );
}
export default AdminOutage