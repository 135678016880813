
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button } from 'primereact/button'
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik'
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import axios from 'axios';
import appUrl from "../../../../constants/appUrl";
import { ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from 'primereact/multiselect';
import 'react-toastify/dist/ReactToastify.css';

import JoditEditor from 'jodit-react';
const AddEditSocialMedia = ({ editable, setRes, rowData, setDialog, onHide, placeholder, productId2 }) => {

    const [productList, setProductList] = useState();
    const [sponserList, setSponserList] = useState();
    const [loading, setLoading] = useState(false);
    const [channelList, setChannelList] = useState();
    const [selectedFiles, setSelectedFiles] = useState([])
    const [apiRes, setApiRes] = useState();
    const [content, setContent] = useState('');
    const editor = useRef(null);
    const allowedFileExtensions = [
        '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.mp3', '.mp4', '.png', '.jpg', '.webm', '.mpeg', '.svg','.mov'
    ];
    const validationSchema = Yup.object({
        postTitle: Yup.string().required('Required').max(30, 'Title must be 30 characters'),
        postDescription: Yup.string().required('Required'),
        // productId: Yup.string().required('Required'),
        channelID: Yup.array().min(1, 'Required').of(Yup.string()).required('Required'),
        sponserID: Yup.string().required('Required'),
        postAddAttachments: Yup.array().min(1, 'At least one attachment is required').required('Required'),



    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            postTitle: '',
            postDescription: '',
            productId: productId2 || '',
            sponserID: '',
            channelID: [],
            postAddAttachments: []


        },

        onSubmit: async (data) => {
            setLoading(true);
            // data.postDescription = content;
            if (!data.productId) {
                data.productId = 0;
            }
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error("No token found. Please log in again.");
                return;
            }
            try {
                {
                    const newData = {
                        postTitle: data.postTitle,
                        postDescription: data.postDescription,
                        productId: data.productId,
                        sponserID: data.sponserID,
                        channelID: data.channelID,
                        postAddAttachments: data.postAddAttachments.map(attachment => ({ attachmentPath: attachment.file }))
                    };
                    if (editable) {
                        newData.postID = rowData.postID;
                        newData.channelID = data.channelID.length > 0 ? data.channelID[0] : null;
                        const response = await axios.post(`${appUrl.baseUrl}api/Post/UpdateNewPost`, newData, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        });
                        setApiRes(response);
                        toast.success("updated Succesfully")
                        setRes(response);
                    } else {
                        newData.channelID = data.channelID;
                        const response = await axios.post(`${appUrl.baseUrl}api/Post/AddNewPost`, newData, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        });
                        setApiRes(response);
                        toast.success("Added Succesfully")

                        setRes(response);
                    }
                }

                setDialog(false);
            } catch (error) {
                toast.error("Something went wrong");
            } finally {
                setLoading(false);
                onHide();

            }
        },

    });


    const handleFileUpload = (event) => {

        const files = event.target.files;
        const imagesArray = Array.from(files);
        let newImagesBase64 = [];
        let hasInvalidFile = false; imagesArray.forEach((file) => {
            const fileExtension = file.name.split('.').pop();
            // if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
            //     toast.error(`${file.name} is not a supported file type`);
            //     hasInvalidFile = true;
            //     return;
            // }
            const isImage = file.type.includes('image')
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                newImagesBase64.push({ isImage, file: base64String, name: file.name });

                if (newImagesBase64.length === imagesArray.length) {
                    formik.setFieldValue("postAddAttachments", [
                        ...formik.values.postAddAttachments,
                        ...newImagesBase64,
                    ]);
                }
            };
            // setSelectedFiles(newImagesBase64)
            reader.readAsDataURL(file);
        });
        if
            (hasInvalidFile) {
            event.target.value = null;

        }
    };

    const handleRemoveImage = (indexToRemove) => {
        const updatedGallery = formik.values.postAddAttachments.filter(
            (_, index) => index !== indexToRemove
        );
        formik.setFieldValue("postAddAttachments", updatedGallery);
    };


    const config = useMemo(() => ({
        readonly: false,
        placeholder: placeholder || ''
    }), [placeholder]);

    useEffect(() => {
        if (editable) {
            formik.setFieldValue('productId', rowData?.productID);
            formik.setFieldValue('channelID', rowData.channelId?.length > 0 ? rowData?.channelId : []);
            formik.setFieldValue('sponserID', rowData?.sponserID);
            formik.setFieldValue('postTitle', rowData?.postTitle);
            formik.setFieldValue('postDescription', rowData?.postDescription);
            if (rowData?.postAddAttachments) {
            
                const postAddAttachmentsWithBaseUrl = rowData.postAddAttachments.map((attachment) => ({
                    isImage: attachment?.attachmentPath.endsWith('.png') || attachment?.attachmentPath.endsWith('.jpg') || attachment?.attachmentPath.endsWith('.jpeg'),
                    file: `${attachment?.attachmentPath}`,
                    name: attachment?.attachmentPath.split('\\').pop(),
                }));
                formik.setFieldValue('postAddAttachments', postAddAttachmentsWithBaseUrl);
                //setSelectedFiles(postAddAttachmentsWithBaseUrl);
            }
        }
    }, [editable, rowData]);

    const getAllSponser = async () => {
        const token =localStorage.getItem('token')

        try {
            const response = await fetch(`${appUrl.baseUrl}api/Post/GetAllSponser`,{
                headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
            })
            const data = await response.json();
            setSponserList(data);
        }
        catch (error) {
            error(error)
        }
    }
    const getAllProduct = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`,{
                headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
            })
            const data = await response.json();
            setProductList(data);
        }
        catch (error) {
            error(error)
        }
    }
    const getAllChannel = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`,{
                headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
            })
            const data = await response.json();
            setChannelList(data);
        }
        catch (error) {
        }
    }
    useEffect(() => {
        getAllProduct()
        getAllSponser()
        getAllChannel()
    }, [])

    return (
        <>
            <ToastContainer />

            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid">
                    <div className="field  md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>Product</label>
                        <Dropdown
                            value={formik.values.productId}
                            optionLabel="productName"
                            name="productId"
                            options={productList}
                            optionValue="productId"
                            placeholder="Select"
                            onChange={formik.handleChange}
                            disabled={editable || productId2 ? true : false}
                        />
                        {/* {formik.touched.productId && formik.errors.productId ? <div className='error'>{formik.errors.productId}</div> : null} */}
                    </div>
                    <div className="field md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>
                            Channel<span style={{ color: 'red' }}>*</span>
                        </label>
                        {editable ? (

                            <MultiSelect
                                value={formik.values.channelID}
                                optionLabel="channelName"
                                name="channelID"
                                options={channelList}
                                optionValue="channelID"
                                placeholder="Select"
                                onChange={formik.handleChange}
                                filter
                                disabled
                            />
                        ) : (
                            <MultiSelect
                                value={formik.values.channelID}
                                optionLabel="channelName"
                                name="channelID"
                                options={channelList}
                                optionValue="channelID"
                                placeholder="Select"
                                onChange={formik.handleChange}
                                filter
                            />
                        )}
                        {formik.touched.channelID && formik.errors.channelID ? (
                            <div className="error">{formik.errors.channelID}</div>
                        ) : null}
                    </div>
                    <div className="field  md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>Sponsor<span style={{ color: 'red' }}>*</span></label>
                        <Dropdown
                            value={formik.values.sponserID}
                            optionLabel="sponserName"
                            name="sponserID"
                            options={sponserList}
                            optionValue="sponserID"
                            placeholder="Select"
                            onChange={formik.handleChange}
                        />
                        {formik.touched.sponserID && formik.errors.sponserID ? <div className='error'>{formik.errors.sponserID}</div> : null}
                    </div>

                    <div className="field  md:col-6">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Title <span className='Staric-Custom'> *</span></label>
                        <InputText
                            id="postTitle"
                            name="postTitle"
                            type="text"
                            value={formik.values.postTitle}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.postTitle && formik.errors.postTitle ? <div className='error'>{formik.errors.postTitle}</div> : null}
                    </div>
                    <div className="field md:col-12">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Description <span className='Staric-Custom'> *</span></label>
                        <JoditEditor
                            ref={editor}
                            value={formik.values.postDescription}
                            config={config}
                            tabIndex={1}
                            onBlur={newContent => setContent(newContent)}
                            onChange={newContent => formik.setFieldValue('postDescription', newContent)}
                        />
                        {formik.touched.postDescription && formik.errors.postDescription ? <div className='error'>{formik.errors.postDescription}</div> : null}
                    </div>


                    <div className='field md:col-12 responsive-col'>
                        <div>
                            <label htmlFor='' style={{ fontWeight: "bold" }}>
                                Upload Attachments<span className='Staric-Custom'> *</span>
                            </label>
                            <br />
                            <input
                                type='file'
                                onChange={(event) => handleFileUpload(event)}
                                accept='.pdf,.doc,.docx,.xls,.xlsx,.mp3,.mp4,.png,.jpg,.webm,.mpeg'
                                multiple
                                className='d-none my-3 '
                            />
                        </div>


                        <div className='block mb-2 upload_images multiple-images'>
                            {editable ?
                                <div className='upload-grid upload-multiple'>
                                    {formik.values.postAddAttachments.map((attachment, index) => (
                                        <div key={index} className='image-preview'>

                                            {attachment.isImage ? <img src={attachment?.file?.includes('data:image') ? attachment?.file : appUrl.baseUrl + attachment?.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                                            <Button
                                                icon='pi pi-times'
                                                type='button'
                                                className='cross-button pt-3'
                                                onClick={() => handleRemoveImage(index)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='upload-grid upload-multiple'>
                                    {formik.values.postAddAttachments.map((attachment, index) => (
                                        <div key={index} className='image-preview'>

                                            {attachment.isImage ? <img src={attachment.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                                            <Button
                                                icon='pi pi-times'
                                                type='button'
                                                className='cross-button pt-3'
                                                onClick={() => handleRemoveImage(index)}
                                            />
                                        </div>
                                    ))}
                                </div>}
                        </div>

                        {formik.touched.postAddAttachments && formik.errors.postAddAttachments ? <div className='error'>{formik.errors.postAddAttachments}</div> : null}

                    </div>

                    <div className="col-12 md:col-8"></div>
                    <div className="col-12 md:col-2" >
                        <div className="flex justify-content-center">
                            <Button className="custom-white w100 pt-3" label='Cancel' type='button' onClick={onHide} />
                        </div>
                    </div>
                    <div className="col-12 md:col-2" >
                        <div className="flex justify-content-center">
                            <Button className="custom-bg" label={editable ? 'Update' : 'Add '} type='submit' disabled={loading} />
                        </div>
                    </div>

                </div>


            </form>
        </>
    )
}

export default AddEditSocialMedia