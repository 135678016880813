import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import axios from 'axios';
import appUrl from "../../../../constants/appUrl";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from "jwt-decode";
const LoginScreen = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const validationSchema = Yup.object({
    userName: Yup.string().required('Email is required').email('Invalid email format'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      userName: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${appUrl.baseUrl}api/Auth/Login`,
          values,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200 && response.data?.token) {
          localStorage.setItem("login", true);
          localStorage.setItem("token", response.data.token);
          
          const decoded = jwtDecode(response.data.token);
       
          let userName = ''
          let isAdmin = ''
          if (decoded)
            Object.keys(decoded).forEach(key => {
          if (key.toLowerCase().includes("role")) {
                isAdmin = decoded[key];
              }
              if (key.toLowerCase().includes("name")) {
                userName = decoded[key];
              }
            });
            localStorage.setItem("admin", isAdmin?.toLowerCase() === 'admin' ? true : false);
            localStorage.setItem("user", userName);

          toast.success('User Login Successfully');
          setTimeout(() => {
            history.push('./');
          }, 1000);
        } else {
          // Show error message from the API response
          toast.error(response.data.message || 'Login failed. Please check your credentials.');
        }
      } catch (error) {
        console.error('Login error:', error);
        if (error.response && error.response.data && error.response.data.message) {
          // Show specific error message from the API response
          toast.error(error.response.data.message);
        } else {
          // Show a generic error message if the response does not contain a message
          toast.error('Login failed due to a network error. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <ToastContainer />

      <div className="p-fluid formgrid grid">
        <div className="field md:col-4"></div>
        <div className="field md:col-4" style={{ background: 'white', padding: '40px', borderRadius: '10px', backdropFilter: 'blur(12px)' }}>
          <div className='text-center'>
            <img src={'assets/layout/images/Kb/Zindigi.svg'} alt="logo" style={{ width: '100px' }} />
          </div>
          <h4 className="auth-welcome mt-3 text-center">Log in to your account</h4>
          <p className='text-center'>Welcome back! Please enter your details.</p>
          <form
            style={{ width: "100%" }}
            className="grid p-fluid justify-content-left align-items-left mt-5"
            onSubmit={formik.handleSubmit}
          >
            <div className='field md:col-12 mb-0'>
              <label className="">Email </label>
              <InputText
                className="auth-welcome"
                placeholder="Enter email"
                type="email"
                id="userName"
                name="userName"
                value={formik.values.userName}
                onChange={formik.handleChange}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <div className="error">{formik.errors.userName}</div>
              ) : null}
            </div>
            <div className='field md:col-12 mb-0'>
              <label className="">Password</label>
              <Password
                className="auth-welcome"
                placeholder="Enter password"
                type="password"
                id="password"
                name="password"
                value={formik.values.password}
                toggleMask
                onChange={formik.handleChange}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className='field md:col-12 mb-0'>
              <Button type="submit" className="custom-btn mt-3" label="Log In" disabled={loading} />
            </div>
          </form>
        </div>
        <div className="field md:col-4"></div>
      </div>
    </div>
  );
};

export default LoginScreen;
