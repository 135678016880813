import React, { useState, useEffect } from 'react';
import ProcessInformation from '../../ProcessInformation/pages/ProcessInformation';
import ComplainHandling from '../../ComplainHandling/pages/ComplainHandling';
import SocialMedia from '../../SocialMedia/pages/SocialMedia';
import ManageFaq from '../../ManageFaq/pages/ManageFaq';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import appUrl from '../../../../constants/appUrl';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { TabPanel, TabView } from 'primereact/tabview';


const ManageProduct = ({ searchQuery }) => {

   const location = useLocation()
   const productId = location?.state?.data;
   const [productList, setProductList] = useState([]);
   const [selectedProductId, setSelectedProductId] = useState(null);

   const GetProductName = async () => {
      const token = localStorage.getItem('token')
      try {
         const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`,
            {
               method: 'GET',
               headers: {
                   'Authorization': `Bearer ${token}`,
                   'Content-Type': 'application/json'
               }
           }
         );
         const data = await response.json();
         setProductList(data);

      } catch (error) {
         console.error('Error fetching :', error);
      }
   };

   const handleGetId = (productId) => {
      setSelectedProductId(productId);
   }
   useEffect(() => {
      GetProductName();
   }, []);

   return (

      <>
         <div>
            <div className="">
               <TabView>
                  <TabPanel header="Process Information">
                     <ProcessInformation product={selectedProductId} productId2={productId} searchQuery={searchQuery} />
                  </TabPanel>
                  <TabPanel header="Complaint Handling">
                     <ComplainHandling product={selectedProductId} productId2={productId} searchQuery={searchQuery} />
                  </TabPanel>
                  <TabPanel header="Tutorial and Video" >
                     <SocialMedia product={selectedProductId} productId2={productId} searchQuery={searchQuery} />
                  </TabPanel>
                  <TabPanel header="FAQs" >
                     <ManageFaq product={selectedProductId} productId2={productId} searchQuery={searchQuery} />
                  </TabPanel>
               </TabView>
            </div>
         </div>
      </>
   );
};

export default ManageProduct;
