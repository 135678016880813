import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog';
import React, { useState, useEffect } from 'react'
import AddEditSocialMedia from '../components/AddEditSocialMedia';
import appUrl from '../../../../constants/appUrl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/laoder';
import axios from 'axios';
import ViewAttachment from '../../ProcessInformation/components/ViewAttachment';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import CustomDescription from '../../../components/CustomDescription';
import CustomVideo from '../../../components/CustomVideo';
import { useHistory } from 'react-router-dom';
const SocialMedia = ({ product, productId2, searchQuery }) => {
  const [dialog, setDialog] = useState(false);
  const [res, setRes] = useState(false);
  const history = useHistory();
  const [socialList, setSocialList] = useState([]);
  const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [editable, setEditable] = useState();
  const [rowselect, setRowselect] = useState(null)
  const [descriptionDialog, setDescriptionDialog] = useState(false);
  const [descriptionContent, setDescriptionContent] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
  const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);
  const [loading, setLoading] = useState(false);

  const getSocialById = async (productId, productId2) => {
    const token = localStorage.getItem('token')
    try {
      setLoading(true);
      const response = await fetch(`${appUrl.baseUrl}api/Post/GetPostByProductId/${productId || productId2}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.status === 401) {
        toast.error('Token expired. Please log in again.');
        window.localStorage.clear();
        history.push('./');
        return;
    }
      const data = await response.json();
      setSocialList(data);
      setLoading(false);
    } catch (error) {
      error('Error fetching :', error);
    }
  };

  useEffect(() => {
    if (product && product.productId || productId2) {
      getSocialById(product?.productId, productId2);
    }
  }, [product, productId2, res]);

  const onHide = () => {
    setDialog(false);
  }


  const actionDocument = (rowData) => {
    const documentsToDownload = rowData.postAttachments.filter(attachment =>
      attachment.attachmentPath.endsWith('.docx') ||
      attachment.attachmentPath.endsWith('.pdf') ||
      attachment.attachmentPath.endsWith('.xlsx')
    );

    const documentPaths = documentsToDownload.map(document => document.attachmentPath);
    const hasImage = rowData.postAttachments.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));
    const hasVideo = rowData.postAttachments.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg'));

    return (
      <div>
        {documentsToDownload.length > 0 && (
          <Button
            icon="pi pi-download"
            className="custom-btn-edit"
            onClick={() => downloadDocuments(documentPaths)}
          />
        )}
        {hasImage && (
          <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
            setSelectedAttachment(rowData.postAttachments.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
            setViewAttachmentDialog(true);
          }} />
        )}
        {hasVideo && (
          <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
            setSelectedVideoAttachment(rowData.postAttachments.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg')));
            setVideoAttachmentDialog(true);
          }} />
        )}
        {(documentsToDownload.length === 0 && !hasImage && !hasVideo) && (
                <Button
                    // icon="pi pi-question"
                    label='-'
                    className="custom-btn-edit"
                    tooltip="No attachment available"
                />
            )}
      </div>
    );
  }
  const downloadDocuments = async (documentPaths) => {
    try {
      for (let i = 0; i < documentPaths.length; i++) {
        const documentPath = documentPaths[i];
        const response = await fetch(`${appUrl.baseUrl}${documentPath}`);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');
        link.click();
        URL.revokeObjectURL(url);
        await new Promise(resolve => setTimeout(resolve, 1000)); // Delay each download by 1 second
      }
    } catch (error) {
      console.error("Error downloading documents:", error);
      toast.error("Failed to download documents");
    }
  };


  const onHideVideoAttachment = () => {
    setVideoAttachmentDialog(false);
  };
  const accept = async (rowData) => {
   
    try {
      const response = await axios.delete(`${appUrl.baseUrl}api/Post/DeletePostByID/${rowData?.postID}/${rowData?.channelID}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {
      toast.error("Failed to delete. Please try again.");
      console.error("Error deleting disclaimer:", error);
    }
  };
  const reject = (rowData) => {
    return
  }
  const confirm = (rowData) => {
    confirmDialog({
      header: (
        <div className="custom-header mb-3 mt-3">
          <i className="pi pi-trash bg-reddd"></i>

        </div>
      ),
      message: (
        <strong>Are you sure you want to delete this Tutorial and Videos?</strong>
      ),
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: 'custom-btn-red',
      rejectClassName: 'custom-white-red',
      className: 'center-buttons no-close-button',
    });
  };


  const actionTemplate = (rowData) => {
    return (
      <div>
        <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
        <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
      </div>
    );
  }
  const editAction = async (rowData) => {
    const token =localStorage.getItem('token')
    try {
      const response = await axios.get(`${appUrl.baseUrl}api/Post/GetPostByPostId/${rowData.postID}/${rowData?.channelID}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 401) {
        toast.error('Token expired. Please log in again.');
        window.localStorage.clear();
        history.push('./');
        return;
    }
      const data = response.data;
      setEditable(true);
      setRowselect(data);
      setDialog(true);
    } catch (error) {
      error("Error fetching outage data by ID:", error);
      toast.error("Failed to fetch data for editing");
    }
  };
  useEffect(() => {
    if (searchQuery) {
      setFilteredData(
        socialList.filter((item) =>
          item.postTitle?.toLowerCase().includes(searchQuery?.toLowerCase())||
          item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())||
          item.channelName?.toLowerCase().includes(searchQuery?.toLowerCase())||
          item.sponserName?.toLowerCase().includes(searchQuery?.toLowerCase())


        )
      );
    } else {
      setFilteredData(socialList);
    }
  }, [searchQuery, socialList]);

  const showDescription = (description) => {
    setDescriptionContent(description);
    setDescriptionDialog(true);
  };
  const onHideDescriptionDialog = () => {
    setDescriptionDialog(false);
  };


  const onHideViewAttachment = () => {
    setViewAttachmentDialog(false);
  };
  const isAdmin = localStorage.getItem('admin')

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };
  return (
    <>
    <ConfirmDialog />
      <ToastContainer />
      <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header={editable ? "Edit " : "Add New"} style={{ width: '50vw' }}>
        {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
      </Dialog>
      <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
        {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
      </Dialog>
      <Dialog visible={dialog} onHide={onHide} header={editable ? "Edit Tutorials & Videos" : "Add Tutorials & Videos"} style={{ width: "60vw" }}>
        <AddEditSocialMedia dialog={dialog} setRes={setRes} setDialog={setDialog} editable={editable} rowData={rowselect} onHide={onHide} productId2={productId2} />
      </Dialog>
      <Dialog visible={descriptionDialog} onHide={onHideDescriptionDialog} header="Description" style={{ width: '50vw' }}>
        <CustomDescription description={descriptionContent} />
      </Dialog>
      <div className='grid'>
        <div className="xl:col-10 md:col-8 sm:col-8 col-4"></div>
        
        {isAdmin === 'true' || isAdmin === true ? (
          <div className='xl:col-2 md:col-4 sm:col-4 col-8'>
            <div>
              <Button className="custom-btn w80" label="+ &nbsp; Add New" onClick={() => { setEditable(false); setDialog(true) }} />
            </div>
          </div>
        ) : null}
      </div>

      <div className='grid'>

        <div className='md:col-12 overflowX' >
        {loading && <Loader />}
          <div className='table-custom'>
            <DataTable
              value={filteredData}
              scrollable

              scrollHeight="500px"
              rows={7}
              paginator
            >


              <Column header="Product" field='productName' />
              {/* <Column header="Channel" field='channelName' /> */}
              <Column header="Channel" className='pointer' body={(rowData) => (
                <span title={rowData.channelName}>
                  {rowData.channelName.length > 15 ? `${rowData.channelName.slice(0, 15)}...` : rowData.channelName}
                </span>
              )} />
              {/* <Column header="Title" field='postTitle' /> */}
              <Column header="Title" className='pointer' body={(rowData) => (
                <span title={rowData.postTitle}>
                  {rowData.postTitle.length > 15 ? `${rowData.postTitle.slice(0, 15)}...` : rowData.postTitle}
                </span>
              )} />
              {/* <Column header="Sponsor" field='sponserName' /> */}
              <Column header="Sponsor" className='pointer' body={(rowData) => (
                <span title={rowData.sponserName}>
                  {rowData.sponserName?.length > 15 ? `${rowData.sponserName.slice(0, 15)}...` : rowData.sponserName}
                </span>
              )} />


              <Column
                header="Description"
                body={(rowData) => (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showDescription(rowData.postDescription);
                    }}
                    style={{ cursor: 'pointer', color: '#00b0ab', textDecoration: 'underline' }}
                  >
                    View Description
                  </a>
                )}
              />
              <Column field='createdDate' header="Created Date / Time"
                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                })} />
              <Column
                field="updatedDate"
                header="Updated Date/Time"
                body={(rowData) => {
                  if (!rowData.updatedDate) {
                    return '-';
                  }
                  return new Date(rowData.updatedDate).toLocaleString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  });
                }}
              />
              {/* <Column header="Attachment" body={actionEye} /> */}
              <Column header="Attachments" body={actionDocument} style={{ minWidth: '12rem' }} />
              {isAdmin === 'true' || isAdmin === true ? (
                <Column header="Action" body={actionTemplate} style={{ minWidth: '10rem' }}/>
              ) : null}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialMedia;
